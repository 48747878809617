// Hook odpowiedzialny za pobranie listy zamówień

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  ICommandResponseError as IError,
  IPaginationRequest,
  IPaginationResponse,
  IStatus
} from 'api/types';

type IDelivery = {
  delivery_date: null | string;
  shipping_date: null | string;
  status: string;
  status_formatted: string;
  tracking_number: string;
  tracking_url: string;
};

export type IPaymentStatus = {
  id: number;
  name: string;
  icon: string;
  color: string;
  show_button_pay: boolean;
  button_type: null | 'SHOW_BANK_DETAILS' | 'INITIALIZE_PAYMENT' | 'HIDDEN';
};

export type IDocument = {
  symbol: string;
  type: string;
};

export interface IOrderListItem {
  id: number;
  lp: number;
  user_id: number;
  user_name: string;
  status: IStatus;
  package_number: string;
  payment_status: string;
  is_blocked: boolean;
  documents?: IDocument[];
  dropshipping: boolean;
  document_name: string;
  status_message: string;
  create_date: string;
  products_total_count: number;
  value_net: number;
  value_net_formatted: string;
  value_gross: number;
  value_gross_formatted: string;
  currency: string;
  completion_date: string;
  is_editable: boolean;
  is_in_edit_mode: boolean;
  has_document_files: boolean;
  delivery: IDelivery[];
  status_payment_formatted: IPaymentStatus;
}

// parametry requestu do api
export type IRequest = IPaginationRequest & {
  searchKeyword?: string;
  orderDateFrom?: string;
  orderDateTo?: string;
  user_ordering?: number;
  status?: string;
  mode?: string;
  sort_method?: string;
};

// typ zwracanych danych
type IResponse = IPaginationResponse<IOrderListItem>;

const getOrders = (params?: IRequest): Promise<IResponse> => axios.get('/orders', { params });

export const useGetOrders = (params?: IRequest, options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['orders', params], () => getOrders(params), options);
